import React from "react";
import "./StartupProjects.css";
import "./Project.css";
import { bigProjects } from "../../portfolio";

export default function HighlightedProject(props) {
  function openProjectInNewWindow(url) {
    let win = window.open(url, "_blank");
    win.focus();
  }

  return (
    <div className="main" id="projects">
      <div>
        <div className="startup-projects-main">
          <div className="startup-project-text">
            {bigProjects.projects.map((project) => {
              return (
                <div
                  className="highlight"
                  onClick={() => openProjectInNewWindow(project.link)}
                >
                  <img className={"StartupProjectImage"} alt="Angelo Working" src={require(`../../assests/images/${project.image}`)}></img>
                  <p className="proj-name" style={{ color: props.theme.text }}>
                    {project.name}
                  </p>
                  <p className="proj-description" style={{ color: props.theme.text }}>{project.description} </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
