import React from "react";
import "./PublicationCard.css";
import "../blogCard/BlogCard.css"
import { Fade } from "react-reveal";

export default function PublicationCard({ blog, theme }) {
  function openRepoinNewTab(url) {
    let win = window.open(url, "_blank");
    win.focus();
  }

  // noinspection JSValidateTypes
  return (
    <div
      className="publication-card-div"
      style={{ backgroundColor: theme.highlight }}
    >
      <Fade bottom duration={2000} distance="40px">
        <div key={blog.title} onClick={() => openRepoinNewTab(blog.link)}>
          <img src={blog.thumbnail} alt="blog" className="blog-card-image mask" />
          <div className="publication-name-div">
            <p className="publication-name" style={{ color: theme.text }}>
              {blog.title}
            </p>
          </div>
          <p className="publication-description" style={{ color: theme.text }}>
            {blog.author}
          </p>
          <p className="publication-description" style={{ color: theme.text }}>
            {blog.pubDate}
          </p>
        </div>
      </Fade>
    </div>
  );
}
