import React, { Component } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../pages/home/HomeComponent";
import Education from "../pages/education/EducationComponent";
//import Experience from "../pages/experience/Experience";
//import Opensource from "../pages/opensource/Opensource";
import Contact from "../pages/contact/ContactComponent";
import Projects from "../pages/projects/Projects";
import Error from "../pages/errors/error404/Error";
import Publication from "../pages/publication/Publication";

export default class Main extends Component {
  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            end
            element={<Home theme={this.props.theme} />}
          />

          {/*<Route
                path="/experience"
                exact
                render={(props) => (
                  <Experience {...props} theme={this.props.theme} />
                )}
              />*/}

          <Route
            path="/education"
            element={
              <Education theme={this.props.theme} />
            }
          />

          {/*   <Route
                path="/opensource"
                render={(props) => (
                  <Opensource {...props} theme={this.props.theme} />
                )}
              />*/}

          <Route
            path="/contact"
            element={
              <Contact theme={this.props.theme} />
            }
          />

          <Route
            path="/projects"
            element={
              <Projects theme={this.props.theme} />
            }
          />
          <Route
            path="/publication"
            element={
              <Publication theme={this.props.theme} />
            }
          />
          <Route
            path="*"
            element={
              <Error theme={this.props.theme} />
            }
          />
        </Routes>
      </BrowserRouter>
    );
  }
}
