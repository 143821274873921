//SEO Related settings
const seo = {
  title: "Angelo's Portfolio",
  description:
    "A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
  og: {
    title: "Angelo Boustany Portfolio",
    type: "website",
    url: "https://boustany.tech/"
  }
};

//Home Page
const greeting = {
  title: "Angelo Boustany",
  logo_name: "AngeloBoustany",
  nickname: "\"The best way to predict the future is to create it.\" - Peter Drucker",
  subTitle:
    "Welcome to my portfolio, where I share my work and experience, from as low as creating a programing language to as high as building predictive ML models and managing cloud infrastructures.",
  resumeLink:
    "https://boustany.tech",
  portfolio_repository: "https://github.com/angeloboustany/",
  githubProfile: "https://github.com/angeloboustany"
};

const socialMediaLinks = [
  /* Your Social Media Link */
  // github: "https://github.com/angeloboustany",
  // linkedin: "https://www.linkedin.com/in/angelo-boustany/",
  // gmail: "angelo@boustany.tech",
  // gitlab: "https://gitlab.com/angeloboustany",
  // facebook: "https://www.facebook.com/angelo.boustany",
  // twitter: "https://twitter.com/angelo-boustany",
  // instagram: "https://www.instagram.com/angelo_boustany/"

  {
    name: "Github",
    link: "https://github.com/angeloboustany",
    fontAwesomeIcon: "fab fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717" // Reference https://simpleicons.org/?q=github
  },
  {
    name: "Gitlab",
    link: "https://gitlab.com/angeloboustany",
    fontAwesomeIcon: "fab fa-gitlab", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836" // Reference https://simpleicons.org/?q=gmail
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/angelo-boustany",
    fontAwesomeIcon: "fab fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5" // Reference https://simpleicons.org/?q=linkedin
  },
  /*{
    name: "YouTube",
    link: "https://www.youtube.com/channel/UC_amoXmmxSY9KusoDczDTXQ",
    fontAwesomeIcon: "fa-youtube", // Reference https://fontawesome.com/icons/youtube?style=brands
    backgroundColor: "#FF0000", // Reference https://simpleicons.org/?q=youtube
  },*/
  {
    name: "Email",
    link: "mailto://angelo@boustany.tech",
    fontAwesomeIcon: "fa fa-at", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836" // Reference https://simpleicons.org/?q=gmail
  },
  /*{
    name: "Twitter",
    link: "https://twitter.com/angelo_boustany",
    fontAwesomeIcon: "fab fa-twitter", // Reference https://fontawesome.com/icons/twitter?style=brands
    backgroundColor: "#1DA1F2" // Reference https://simpleicons.org/?q=twitter
  },*/
  /*{
    name: "Facebook",
    link: "https://www.facebook.com/angelo.boustany",
    fontAwesomeIcon: "fab fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
    backgroundColor: "#1877F2" // Reference https://simpleicons.org/?q=facebook
  },*/
  /*{
    name: "Instagram",
    link: "https://www.instagram.com/angelo_boustany/",
    fontAwesomeIcon: "fab fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
    backgroundColor: "#E4405F" // Reference https://simpleicons.org/?q=instagram
  }*/
  {
    name: "Slack",
    link: "mailto://angeloboustany5@gmail.com",
    fontAwesomeIcon: "fab fa-slack", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836" // Reference https://simpleicons.org/?q=gmail
  },


];

const skills = {
  data: [
    {
      title: "Data Science & AI",
      fileName: "DataScienceImg",
      skills: [
        "⚡ Clean, Manipulate, and Visualize Data",
        "⚡ Experience with some of the most popular Python libraries, including Pandas, NumPy, Matplotlib, Tensorflow",
        "⚡ Knowledge in statistical and machine learning techniques needed to perform hypothesis testing and build predictive models.",
        "⚡ Familiarity with web scraping and web scraping libraries such Selenium, and BeautifulSoup",
      ],
      softwareSkills: [
        {
          skillName: "Tensorflow",
          fontAwesomeClassname: "logos-tensorflow",
          style: {
            backgroundColor: "transparent"
          }
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "logos:python",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB"
          }
        },
        {
          skillName: "Numpy",
          fontAwesomeClassname: "logos:numpy",
          style: {
            backgroundColor: "transparent"
          }
        },
        {
          skillName: "Pandas",
          fontAwesomeClassname: "simple-icons:pandas",
          style: {
            backgroundColor: "transparent"
          }
        },
        {
          skillName: "Matplotlib",
          fontAwesomeClassname: "logos:matplotlib-icon",
          style: {
            backgroundColor: "transparent"
          }
        },
        {
          skillName: "Selenium",
          fontAwesomeClassname: "logos:selenium",
          style: {
            backgroundColor: "transparent"
          }
        }

      ]
    },
    {
      title: "Software Development",
      fileName: "ProgrammingImg",
      skills: [
        "⚡ Created a programing language with c++ and java",
        "⚡ Scripting and Automation",
        "⚡ Learning AMD64 to strengthen my fundamentals in programming",
      ],
      softwareSkills: [
        {
          skillName: "C++",
          fontAwesomeClassname: "vscode-icons:file-type-cpp3",
          style: {
            backgroundColor: "transparent"
          }
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "logos:python",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB"
          }
        },
        {
          skillName: "Java",
          fontAwesomeClassname: "logos:java",
          style: {
            backgroundColor: "transparent"
          }
        },
        {
          skillName: "AMD64",
          fontAwesomeClassname: "simple-icons:assemblyscript",
          style: {
            backgroundColor: "transparent"
          }
        },
      ]
    },
    {
      title: "Full Stack Web Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Building responsive website front end using React",
        "⚡ Creating application backend and RESTful api with PHP, Flask, Django",
        "⚡ Developing Databases using MySQL, MongoDB, and PostgreSQL",
        "⚡ Hand on experience with Wordpress and its plugins",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26"
          }
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6"
          }
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E"
          }
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB"
          }
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933"
          }
        },
        {
          skillName: "PHP",
          fontAwesomeClassname: "logos:php",
          style: {
            color: "#339933"
          }
        },
        {
          skillName: "Mysql",
          fontAwesomeClassname: "logos:mysql",
          style: {
            color: "#339933"
          }
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#339933"
          }
        },
        {
          skillName: "Flask",
          fontAwesomeClassname: "logos:flask",
          style: {
            color: "#339933"
          }
        },
        {
          skillName: "Wordpress",
          fontAwesomeClassname: "logos:wordpress",
          style: {
            color: "#339933"
          }
        },

      ]
    },
    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Hosting and maintaining websites on virtual machine instances along with integration of databases",
        "⚡ Deploying and maintaining applications on cloud platforms like Azure, Heroku",
        "⚡ CI/CD workflow using Git, GitHub, and Travis CI",
        "⚡ CloudFlare for DNS management"
      ],
      softwareSkills: [
        {
          skillName: "Azure",
          fontAwesomeClassname: "simple-icons:microsoftazure",
          style: {
            color: "#0089D6"
          }
        },
        {
          skillName: "Heroku",
          fontAwesomeClassname: "logos:heroku-icon",
          style: {
            color: "#FFCA28"
          }
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6"
          }
        },
        {
          skillName: "Git",
          fontAwesomeClassname: "logos:git-icon",
          style: {
            color: "#1488C6"
          }
        },
        {
          skillName: "Mercurial",
          fontAwesomeClassname: "vscode-icons:file-type-mercurial",
          style: {
            color: "#1488C6"
          }
        },
        {
          skillName: "GitHub",
          fontAwesomeClassname: "logos:github-icon",
          style: {
            color: "#1488C6"
          }
        },
        {
          skillName: "CloudFlare",
          fontAwesomeClassname: "logos:cloudflare",
          style: {
            color: "#1488C6"
          }
        },
      ]
    }
  ]
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "HackerRank",
      iconifyClassname: "simple-icons:hackerrank",
      style: {
        color: "#2EC866"
      },
      profileLink: "https://www.hackerrank.com/angeloboustany"
    },
    {
      siteName: "Codechef",
      iconifyClassname: "simple-icons:codechef",
      style: {
        color: "#5B4638"
      },
      profileLink: "https://www.codechef.com/users/gin_toniq"
    },
    {
      siteName: "Codeforces",
      iconifyClassname: "simple-icons:codeforces",
      style: {
        color: "#1F8ACB"
      },
      profileLink: "https://codeforces.com/profile/Angel0"
    },
    {
      siteName: "Hackerearth",
      iconifyClassname: "simple-icons:hackerearth",
      style: {
        color: "#323754"
      },
      profileLink: "https://www.hackerearth.com/@angelo60"
    }
    /*{
      siteName: "Kaggle",
      iconifyClassname: "simple-icons:kaggle",
      style: {
        color: "#20BEFF",
      },
      profileLink: "https://www.kaggle.com/laymanbrother",
    },*/
  ]
};

const degrees = {
  degrees: [
    {
      title: "Beirut Arab University ",
      subtitle: "BSc in Computer Science",
      logo_path: "bau_logo.png",
      alt_name: "BAU",
      duration: "2021 - Present",
      descriptions: [
        "⚡ I have studied software engineering subjects like DS, Algorithms, DBMS, OS, CA, AI etc.",
        "⚡ Apart from this, I have done courses on Deep Learning, Data Science, Cloud Computing and Full Stack Development.",
        "⚡ Current GPA: 4~",
        "⚡ Ranked 1th of the first year student 2021/2022"
      ],
      website_link: "https://bau.edu.lb"
    }
  ]
};

const certifications = {
  certifications: [
   /* {
      title: "Machine Learning",
      subtitle: "- Andrew Ng",
      logo_path: "stanford_logo.png",
      certificate_link:
        "https://www.coursera.org/",
      alt_name: "Stanford University",
      color_code: "#8C151599"
    }*/
  ]
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description:
    "I have worked with many evolving startups as ML and DL Developer, Designer and Software Architect. I have also worked with some well established companies mostly as AI Developer. I love organising events and that is why I am also involved with many opensource communities as a representative.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      experiences: [
        /* {
           title: "Associate AI Engineer",
           company: "Legato Health Technology",
           company_url: "https://legatohealthtech.com/",
           logo_path: "legato_logo.png",
           duration: "June 2020 - PRESENT",
           location: "Hyderabad, Telangana",
           description:
             "I am working on automating healthcare products. The projects involve automation for process improvements and for significantly enhancing the profits. I am currently working on Cancer Survival and Reoccurence Prediction. Our goal is to make AI system which scales and removes doctor dependency as much as possible.",
           color: "#0879bf"
         },*/
      ]
    },
    {
      title: "Internships",
      experiences: [
        /*        {
                  title: "Data Science Research Intern",
                  company: "Delhivery Pvt. Ltd.",
                  company_url: "https://www.delhivery.com/",
                  logo_path: "delhivery_logo.png",
                  duration: "May 2019 - Sept 2019",
                  location: "Gurgaon, Haryana",
                  description:
                    "I have worked on project of predicting freight rates based on previous data. There were two objectives: (1) To build a forecasting engine to predict daily freight rates. (2) To embed feature in the model which can explain the seasonal major changes in freight rate based on regions and locations. I have closely worked with deep learning models in combination with statistical methods to create solution for this. At the end of internship, I had created model deployed on AWS EC2 with the use of Kafka stream jobs, ElasticSearch and PostgreSQL.",
                  color: "#ee3c26"
                }*/

      ]
    },
    {
      title: "Volunteerships",
      experiences: [
        /*
        {
          title: "Google Explore ML Facilitator",
          company: "Google",
          company_url: "https://about.google/",
          logo_path: "google_logo.png",
          duration: "June 2019 - April 2020",
          location: "Hyderabad, Telangana",
          description:
            "Explore Machine Learning (ML) is a Google-sponsored program for university students to get started with Machine Learning. The curriculum offers 3 tracks of ML Content (Beginner, Intermediate, Advanced) and relies on university student facilitators to train other students on campus and to build opensource projects under this program.",
          color: "#4285F4"
        },
*/
      ]
    }
  ]
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "Tinkering with code is one of the best ways to learn. Here you can find my latest projects including my work on a new programming language and several useful websites and scripts.",
  avatar_image_path: "projects_image.svg"
};

/*const publicationsHeader = {
  title: "Publications",
  description:
    "Writing about my interest and research",
  avatar_image_path: "projects_image.svg",
};*/

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "angelo_profile.jpg",
    description:
      "Feel free to call, send me and email, or schedule a meeting down below, I almost always respond within 24 hours. Let the fun begin :)",
  },
  blogSection: {
    title: "Publications",
    subtitle:
      "Writing about my interest and research",
    link: "https://medium.com/@boustany",
    avatar_image_path: "blogs_image.svg"
  },
  addressSection: {
    title: "Address",
    subtitle:
      "Lebanon Chouf Debbieh 1054",
    avatar_image_path: "address_image.svg"
  },
  phoneSection: {
    title: "Phone Number",
    subtitle: "+961 71204311"
  }
};

const bigProjects = {
  title: "Highlights",
  subtitle: "My projects makes use of vast variety of latest technology tools.",
  projects: [
   /* {
      name: "test",
      description: "lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
      link: "https://google.com",
      image: "bau_logo.png"
    },*/
    ]
};

export {
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  //publicationsHeader,
  contactPageData,
  bigProjects
};
