import React, { Component } from "react";

export default class CloudInfraImg extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <svg
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        width="667.44532"
        height="589.48587"
        viewBox="0 0 667.44532 589.48587"
      >
        <path
          d="M783.23262,532.89214a7.72132,7.72132,0,0,1,4.04472-11.12741l-.39756-27.43609,12.016,7.67645L797.4311,526.882a7.76317,7.76317,0,0,1-14.19848,6.01015Z"
          transform="translate(-266.27734 -155.25706)"
          fill="#ffb9b9"
        />
        <path
          d="M768.75126,383.9362A15.63963,15.63963,0,0,0,755.837,398.99425c-.174,8.02961,2.50557,11.02363,6.29215,12.10551,7,2,18.14816,42.44832,18.14816,42.44832s1.18325,37.72571,3.09163,35.86286-.25137,2.4842.3285,3.81067-2.74888,3.313.4155,4.81973,1.16437,13.50674,1.16437,13.50674l14-1s-2.32454-9.77573.83773-10.38786,1.72534-3.39426.44381-5.5032,2.26732-5.11484.49289-6.11189,3.22557-39.997,3.22557-39.997L784.7285,391.02842a15.64187,15.64187,0,0,0-15.97722-7.09223Z"
          transform="translate(-266.27734 -155.25706)"
          fill={theme.text}
        />
        <polygon
          points="460 230.943 470.071 213.291 483.677 213.291 493.882 231.943 460 230.943"
          fill={theme.text}
        />
        <path
          d="M699.18878,227.46306c-8.04694-26.95441-30.7807-44.551-58.25122-48.41126-.30219-.0426-.589.01367-.8899-.00574a8.68011,8.68011,0,0,0-1.18756-.27349c-37.778-4.88251-79.0177,3.86193-111.84076,24.3988-31.46228,19.68549-67.61309,30.793-104.72552,30.5462q-1.72879-.01145-3.47113.075c-28.56079,1.42511-53.76636,22.21533-61.48755,49.5606-1.01331,3.58777.75244,6.79352,3.59186,8.82673,4.98932,3.57348,8.3974,6.078,14.53607,7.37841q16.43014,3.48075,32.9726,6.40137V262.03075a15.70447,15.70447,0,0,1,15.68627-15.68628H639.59106a15.70448,15.70448,0,0,1,15.68628,15.68628V316.738a64.51829,64.51829,0,0,0,8.63245-3.39789,66.83714,66.83714,0,0,0,25.76849-22.487C702.15289,272.75279,705.44769,248.42662,699.18878,227.46306Z"
          transform="translate(-266.27734 -155.25706)"
          fill="#f2f2f2"
        />
        <path
          d="M608.4671,484.54808H417.99a16.59835,16.59835,0,0,1-16.57911-16.5791V394.05852a172.96506,172.96506,0,0,0-71.7163-6.286,8.68011,8.68011,0,0,0-1.18756.27349c-.30085.01941-.58771-.03686-.8899.00574-27.47052,3.86029-50.20428,21.45685-58.25122,48.41126-6.25891,20.96356-2.96411,45.28973,9.5105,63.39a66.83714,66.83714,0,0,0,25.76849,22.487c14.28736,6.88226,29.32532,7.39972,44.91919,7.88568a1018.27653,1018.27653,0,0,0,140.261-5.30163q34.38208-3.68766,68.47858-9.63837,17.4516-3.0541,34.788-6.72742c6.13867-1.30041,9.54675-3.80493,14.53607-7.37841,2.83942-2.03321,4.60517-5.239,3.59186-8.82673A65.80907,65.80907,0,0,0,608.4671,484.54808Z"
          transform="translate(-266.27734 -155.25706)"
          fill="#f2f2f2"
        />
        <path
          d="M625.32431,462.62108H438.38871a13.62481,13.62481,0,0,1-13.609-13.609V275.28913a13.62481,13.62481,0,0,1,13.609-13.609h186.9356a13.6248,13.6248,0,0,1,13.609,13.609V449.01205A13.6248,13.6248,0,0,1,625.32431,462.62108Z"
          transform="translate(-266.27734 -155.25706)"
          fill="#e6e6e6"
        />
        <path
          d="M585.97983,453.4416H447.57038a13.62481,13.62481,0,0,1-13.609-13.609V284.47592a13.62481,13.62481,0,0,1,13.609-13.609H616.14483a13.62481,13.62481,0,0,1,13.609,13.609V409.66757A43.82338,43.82338,0,0,1,585.97983,453.4416Z"
          transform="translate(-266.27734 -155.25706)"
          fill="#fff"
        />
        <circle cx="265.58027" cy="206.89718" r="61" fill={theme.text} />
        <path
          d="M557.5542,359.08519l-20.356-24.75391a6.00075,6.00075,0,0,0-9.14014-.15136l-21.76855,24.7539a6.00029,6.00029,0,0,0,9.01172,7.92481L526.564,354.05119v32.115a6,6,0,0,0,12,0V354.8853l9.72168,11.822a6,6,0,0,0,9.26856-7.62207Z"
          transform="translate(-266.27734 -155.25706)"
          fill="#fff"
        />
        <polygon
          points="528.453 578.324 519.404 578.323 515.099 543.416 528.456 543.417 528.453 578.324"
          fill="#ffb9b9"
        />
        <path
          d="M795.04973,743.0215l-27.82942-.001v-.35195a10.83258,10.83258,0,0,1,10.832-10.83181h.00067l5.0834-3.85654,9.4845,3.85712,2.42932.00009Z"
          transform="translate(-266.27734 -155.25706)"
          fill={theme.dark}
        />
        <polygon
          points="460.453 578.324 451.404 578.323 447.099 543.416 460.456 543.417 460.453 578.324"
          fill="#ffb9b9"
        />
        <path
          d="M727.04973,743.0215l-27.82942-.001v-.35195a10.83258,10.83258,0,0,1,10.832-10.83181h.00067l5.0834-3.85654,9.4845,3.85712,2.42932.00009Z"
          transform="translate(-266.27734 -155.25706)"
          fill={theme.dark}
        />
        <path
          d="M797.61645,704.6906c3.30164-2.1576,1.38892-2.01752,1.38892-2.01752s-7.383-59.85993-13-78l-6.49994-88.33655s-.41266,1.76215,0-2.52069,3.41266-1.28284.41266-4.28284-5-4.52868-3-6.76434,7.51721-6.75861,4.2586-9.49713-9.67126-20.59845-9.67126-20.59845l-62.50006.5,2.5-.5s-7.80182-2.89459-4.228.875-2,13-4,22c-1.65643,7.454-6.57379,9.43207,0,11,6.57379,1.56787.62476,6.01092.62476,6.01092s-9.89673,47.11408-2.39673,78.11408c0,0,5.17413,76.67175,7.79339,83.26587,2.61933,6.59405,3.61933,2.59405,2.61933,6.59405s-3-3-1,4a92.51269,92.51269,0,0,1,2.58728,11.14008l13.56231-1s3.1496-9.92743,0-12.53375-.97491,5,2.43775-.80316-.58734-3.80317-1.58734-11.80317,8.17468-45.2677,1.58734-68.13385l12.17328-64.16278,19.32666,66.43671s10.25128,81.14014,13.582,84.00006c3.33069,2.85986,1.9856-4.08209,3.33069,2.85986,1.35925,7.01508,4.44653,5.15515,4.35925,6.01508-.93127,9.17242,15.33911.125,15.33911.125a51.36513,51.36513,0,0,1,0-6.99994C797.91809,707.533,794.31482,706.84813,797.61645,704.6906Z"
          transform="translate(-266.27734 -155.25706)"
          fill={theme.dark}
        />
        <path
          d="M715.27734,513.54808c-4,0-15-5.41732-13-7.70866s2-9.89764,2-12.09449,9-83.19685,9-83.19685l-.68388-19.83247a8.86212,8.86212,0,0,1,8.85686-9.16753l8.827-3,25-1,12.46645,4A7.22686,7.22686,0,0,1,774.181,392.0598l0,0a65.07422,65.07422,0,0,1,9.55278,35.95453c-.28682,9.47129-5.08528,21.038-6.24161,31.73814-.1015.93921-.17494,1.87174-.2148,2.79559-.01112.25761-2.05815,1.54144-.02907.77072s-1.08747,1.94967-.0292,3.58947,3.10929.635.08378,2.63741,3.63258,21.34431,2.97449,22.0024c-2,2-6,3-3,6s6,1,2,5-15.98425,11-15.98425,11Z"
          transform="translate(-266.27734 -155.25706)"
          fill={theme.secondaryText}
        />
        <circle cx="475.73406" cy="189.15557" r="21.52319" fill="#ffb9b9" />
        <path
          d="M610.92115,411.192a7.72133,7.72133,0,0,1,10.62241,5.2291l27.317,2.584-8.93584,11.11135L615.354,425.9591a7.76317,7.76317,0,0,1-4.43284-14.76715Z"
          transform="translate(-266.27734 -155.25706)"
          fill="#ffb9b9"
        />
        <path
          d="M740.44091,395.17016a15.63965,15.63965,0,0,0-15.058-12.91423c-8.02961-.174-11.02364,2.50557-12.10552,6.29215-2,7-34.19685,31.60617-34.19685,31.60617l-27.65117-2.99243c-1.66278-.54016-.87427-2.05438-7.12586-.77115-3.07953-2.01669-5.07055-1.675-6.785-.73428l-10.24109-1.10831,1,16s10.96343-2.608,13,2,1.57046,5.26992,6,2,4,0,4,0l32,8,50.07135-31.40068a15.64187,15.64187,0,0,0,7.09222-15.97722Z"
          transform="translate(-266.27734 -155.25706)"
          fill={theme.text}
        />
        <path
          d="M750.36737,363.628l1.41.45c-4.41.37-12.27,2.83-11.5,1.47s-2.14-6.49-.72-7.14a9.03069,9.03069,0,0,0,3.82-2.59c.9-1.26.88-3.34-.47-4.11-1.31-.76-2.93.06-4.23.83-1.31.76-3.73-10.42-3.11-13.91-3.65-2-8.07,1.83-12.17,1.08-2.51-.46-4.32-2.52-5.46-4.86a15.246,15.246,0,0,0,4.03-6.42,19.90965,19.90965,0,0,1-5.52,1.83,12.97091,12.97091,0,0,1,.14-5.48,8.94283,8.94283,0,0,1,5.57-6.34,6.48235,6.48235,0,0,1,7.6,2.82,6.44436,6.44436,0,0,0-4.08-6.4,6.77636,6.77636,0,0,1,9.75,4.61,20.93429,20.93429,0,0,1,14.59-1.56c4.84,1.27,11.26,6.64,11.26,6.64C772.19732,326.95805,771.67736,368.47807,750.36737,363.628Z"
          transform="translate(-266.27734 -155.25706)"
          fill="#2f2e41"
        />
        <path
          d="M864.39375,734.43979a1.2164,1.2164,0,0,0,.88157-2.10832l-.08335-.33136.03312-.07954a3.26656,3.26656,0,0,1,6.02555.02239c.98551,2.37356,2.24023,4.75117,2.54918,7.26082a9.66393,9.66393,0,0,1-.16964,3.32354,38.74392,38.74392,0,0,0,3.52438-16.09157,37.39746,37.39746,0,0,0-.232-4.17178q-.19217-1.70334-.53318-3.38243a39.19285,39.19285,0,0,0-7.77228-16.61089A10.43034,10.43034,0,0,1,864.2792,697.77a7.95618,7.95618,0,0,1-.72357-2.17417,9.819,9.819,0,0,0,.637-3.3859c.2943-.44659.82105-.66859,1.14248-1.10437,1.59857-2.16733,3.801-1.78891,4.95074,1.15632,2.45615,1.23962,2.47993,3.29545.97284,5.27269-.95881,1.25793-1.09055,2.96-1.93184,4.30676.08657.1108.17659.21813.26313.32893a39.4457,39.4457,0,0,1,4.11715,6.52255,16.39818,16.39818,0,0,1,.979-7.61655c.93706-2.26054,2.69343-4.16438,4.24012-6.11862a3.36576,3.36576,0,0,1,5.9948,1.65271l.00927.0864q-.3446.1944-.67464.41269a1.65,1.65,0,0,0,.66523,3.00262l.03364.00518a16.41807,16.41807,0,0,1-.43274,2.45462c1.98421,7.67347-2.29957,10.46831-8.41623,10.59386-.135.06923-.2666.13847-.40163.20427a40.262,40.262,0,0,1,2.16725,10.19917,38.18677,38.18677,0,0,1-.0277,6.1659l.0104-.07269a10.09082,10.09082,0,0,1,3.44475-5.82663c2.65092-2.17763,6.39617-2.97954,9.256-4.72993a1.89376,1.89376,0,0,1,2.90049,1.84233l-.01169.07644a11.08173,11.08173,0,0,0-1.24287.5989q-.3446.19445-.67465.41269a1.65,1.65,0,0,0,.66524,3.00265l.03367.00517.0692.01042a16.43116,16.43116,0,0,1-3.02234,4.73259c-1.24068,6.69864-6.56941,7.3342-12.2695,5.38348h-.00347a40.251,40.251,0,0,1-2.70387,7.89347h-9.65909c-.03464-.10732-.06579-.21812-.097-.32545a10.97506,10.97506,0,0,0,2.67273-.15921c-.71666-.87938-1.43329-1.76569-2.14994-2.645a.60206.60206,0,0,1-.045-.05194c-.36351-.45-.73049-.89666-1.094-1.34671l-.0002-.00055a16.07543,16.07543,0,0,1,.471-4.09507Z"
          transform="translate(-266.27734 -155.25706)"
          fill="#f2f2f2"
        />
        <path
          d="M542.80633,744.4356l389.72565.30734a1.19068,1.19068,0,0,0,0-2.38137l-389.72565-.30733a1.19068,1.19068,0,0,0,0,2.38136Z"
          transform="translate(-266.27734 -155.25706)"
          fill="#cacaca"
        />
        <path
          d="M522.8955,577.54808A14.14551,14.14551,0,1,1,537.041,563.40257,14.16159,14.16159,0,0,1,522.8955,577.54808Zm0-26.291A12.14551,12.14551,0,1,0,535.041,563.40257,12.159,12.159,0,0,0,522.8955,551.25706Z"
          transform="translate(-266.27734 -155.25706)"
          fill="#f2f2f2"
        />
        <path
          d="M528.43314,562.741l-4.38677-5.33454a1.29319,1.29319,0,0,0-1.96972-.03262l-4.69119,5.33454a1.29308,1.29308,0,0,0,1.942,1.70782l2.42714-2.76v6.92088a1.293,1.293,0,1,0,2.586,0v-6.74113l2.09505,2.54767a1.293,1.293,0,0,0,1.9974-1.64258Z"
          transform="translate(-266.27734 -155.25706)"
          fill="#f2f2f2"
        />
        <path
          d="M683.8955,368.54808A14.14551,14.14551,0,1,1,698.041,354.40257,14.16159,14.16159,0,0,1,683.8955,368.54808Zm0-26.291A12.14551,12.14551,0,1,0,696.041,354.40257,12.159,12.159,0,0,0,683.8955,342.25706Z"
          transform="translate(-266.27734 -155.25706)"
          fill="#f2f2f2"
        />
        <path
          d="M689.43314,353.741l-4.38677-5.33454a1.29319,1.29319,0,0,0-1.96972-.03262l-4.69119,5.33454a1.29308,1.29308,0,0,0,1.942,1.70782l2.42714-2.76v6.92088a1.293,1.293,0,1,0,2.586,0v-6.74113l2.09505,2.54767a1.293,1.293,0,0,0,1.9974-1.64258Z"
          transform="translate(-266.27734 -155.25706)"
          fill="#f2f2f2"
        />
        <path
          d="M364.8955,356.54808A14.14551,14.14551,0,1,1,379.041,342.40257,14.16159,14.16159,0,0,1,364.8955,356.54808Zm0-26.291A12.14551,12.14551,0,1,0,377.041,342.40257,12.159,12.159,0,0,0,364.8955,330.25706Z"
          transform="translate(-266.27734 -155.25706)"
          fill="#f2f2f2"
        />
        <path
          d="M370.43314,341.741l-4.38677-5.33454a1.29319,1.29319,0,0,0-1.96972-.03262l-4.69119,5.33454a1.29308,1.29308,0,0,0,1.942,1.70782l2.42714-2.76v6.92088a1.293,1.293,0,0,0,2.586,0v-6.74113l2.09505,2.54767a1.293,1.293,0,0,0,1.9974-1.64258Z"
          transform="translate(-266.27734 -155.25706)"
          fill="#f2f2f2"
        />
        <path
          d="M477.8955,183.54808A14.14551,14.14551,0,1,1,492.041,169.40257,14.16159,14.16159,0,0,1,477.8955,183.54808Zm0-26.291A12.14551,12.14551,0,1,0,490.041,169.40257,12.159,12.159,0,0,0,477.8955,157.25706Z"
          transform="translate(-266.27734 -155.25706)"
          fill="#f2f2f2"
        />
        <path
          d="M483.43314,168.741l-4.38677-5.33454a1.29319,1.29319,0,0,0-1.96972-.03262l-4.69119,5.33454a1.29308,1.29308,0,0,0,1.942,1.70782l2.42714-2.76v6.92088a1.293,1.293,0,0,0,2.586,0v-6.74113l2.09505,2.54767a1.293,1.293,0,0,0,1.9974-1.64258Z"
          transform="translate(-266.27734 -155.25706)"
          fill="#f2f2f2"
        />
        <path
          d="M326.8955,461.54808A14.14575,14.14575,0,1,1,341.041,447.40257,14.16159,14.16159,0,0,1,326.8955,461.54808Zm0-26.2915a12.14575,12.14575,0,1,0,12.14551,12.146A12.15941,12.15941,0,0,0,326.8955,435.25658Z"
          transform="translate(-266.27734 -155.25706)"
          fill="#fff"
        />
        <path
          d="M332.43314,446.741l-4.38677-5.33454a1.29319,1.29319,0,0,0-1.96972-.03262l-4.69119,5.33454a1.29308,1.29308,0,0,0,1.942,1.70782l2.42714-2.76v6.92088a1.293,1.293,0,0,0,2.586,0v-6.74113l2.09505,2.54767a1.293,1.293,0,0,0,1.9974-1.64258Z"
          transform="translate(-266.27734 -155.25706)"
          fill="#fff"
        />
        <path
          d="M632.8955,224.54808A14.14575,14.14575,0,1,1,647.041,210.40257,14.16159,14.16159,0,0,1,632.8955,224.54808Zm0-26.2915a12.14575,12.14575,0,1,0,12.14551,12.146A12.15941,12.15941,0,0,0,632.8955,198.25658Z"
          transform="translate(-266.27734 -155.25706)"
          fill="#fff"
        />
        <path
          d="M638.43314,209.741l-4.38677-5.33454a1.29319,1.29319,0,0,0-1.96972-.03262l-4.69119,5.33454a1.29308,1.29308,0,0,0,1.942,1.70782l2.42714-2.76v6.92088a1.293,1.293,0,1,0,2.586,0v-6.74113l2.09505,2.54767a1.293,1.293,0,0,0,1.9974-1.64258Z"
          transform="translate(-266.27734 -155.25706)"
          fill="#fff"
        />
      </svg>
    );
  }
}
