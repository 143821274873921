import React from "react";
import "./Button.css";

const onMouse = (event, color, bgColor) => {
  const el = event.target;
  el.style.color = color;
  el.style.backgroundColor = bgColor;
};

export default function Button(props) {
  const theme = props.theme;
  const href = props.href;
  const newTab = props.newTab;
  const text = props.text;
  const className = props.className;

  return (
    <div className={className}>
      <a
        className="main-button"
        href={href}
        target={newTab && "_blank"}
        style={{
          color: theme.body,
          backgroundColor: theme.text,
          border: `solid 1px ${theme.text}`,
        }}
        onMouseEnter={(event) => onMouse(event, theme.text, theme.highlight)}
        onMouseOut={(event) => onMouse(event, theme.highlight, theme.text)}
      >
        {text}
      </a>
    </div>
  );
}
